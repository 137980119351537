import { Button, Col, Form, Image, Input, Row, Select, Statistic, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import accountIcon from 'assets/image/account.svg';
import { ModalLayout } from "components/layout.component";
import { COOKIE } from "constants/cookie";
import useIcon from 'hooks/icon.hook';
import i18n from 'i18n';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { generatePath, useNavigate } from "react-router-dom";
import { $post } from "services";

// 代理驗證

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const icon = useIcon();
  const { Countdown } = Statistic;
  const [afterMessage, setAfterMessage] = useState(false);
  const [cooldown, setCooldown] = useState(false);

  const [formMessage] = useForm();
  const [formVerify] = useForm();

  // 送簡訊
  const finishMessage = async (formData: any) => {
    const send = {
      PhonePrefix: formData.PhonePrefix,
      Phone: formData.Phone,
    };
    $post({
      url: 'common/verify/phone/verify',
      send,
      success: () => {
        message.success(i18n.t('sendedMessage'));
        setAfterMessage(true);
        setCooldown(true);
      }
    })
  };

  // 送驗證
  const finishVerify = async (formData: any) => {
    const send = {
      Code: formData.Code,
    };
    $post({
      url: 'common/verify/phone/verified',
      send,
      success: () => {
        message.success(i18n.t('verifySuccess'));
        navigate({ pathname: generatePath('/password', { id: formData.account }) });
      }
    })
  };

  return (
    <ModalLayout>
      <Form form={formMessage} size="middle" onFinish={finishMessage} initialValues={{
        account: Cookies.get(COOKIE.ACCOUNT),
        PhonePrefix: '+886',
      }}>
        <Form.Item className="login-title" style={{ textAlign: 'center', color: '#8C8372' }}>
          <p>{i18n.t('agentBackend')}</p>
        </Form.Item>
        <Form.Item name="account">
          <Input
            disabled
            prefix={
              <Image rootClassName="center" src={accountIcon} width={20} height={20} preview={false} />
            }
            placeholder={`${i18n.t("inputData")}${i18n.t("account")}`}
          />
        </Form.Item>
        <Row className="mt-1" align="top" gutter={5}>
          <Col span={8}>
            <Form.Item name="PhonePrefix">
              <Select
                className="w-full"
                options={[
                  { value: '+63', label: '+63' },
                  { value: '+886', label: '+886' },
                  { value: '+84', label: '+84' },
                  { value: '+853', label: '+853' },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item name="Phone" rules={[{ required: true, message: `${i18n.t('inputMobile')}` }]}>
              <Input className="inputMobile" placeholder={`${i18n.t("inputMobile")}`} />
            </Form.Item>
          </Col>
        </Row>
        <Button
          htmlType="submit"
          style={{ background: '#DABF8D' }}
          className={`mt-1 w-full login-btn send-message ${cooldown && 'bg-color-07'}`}
          size="large"
          disabled={cooldown}
        >
          <Row align="middle" justify="center">
            {cooldown
              ? <>
                <span>{`${i18n.t('sendedMessage')}(`}</span>
                <Countdown
                  format="ss"
                  value={Date.now() + 1000 * 31}
                  onFinish={() => setCooldown(false)} valueStyle={{ fontSize: 14, color: '#d7d7d7' }}
                />
                <span>{`)`}</span>
              </>
              : i18n.t("sendVerificationMessage")
            }
          </Row>
        </Button>
      </Form>

      <Form size="middle" className="mt-1" form={formVerify} onFinish={finishVerify}>
        <Form.Item className="mt-1" name="Code" rules={[{ required: true, message: `${i18n.t('inputVerifyCode')}` }]}>
          <Input className="inputVerifyMobile" placeholder={`${i18n.t("inputVerifyCode")}`} disabled={!afterMessage} />
        </Form.Item>
        <Button
          htmlType="submit"
          style={{ background: '#DABF8D' }}
          className="mt-1 w-full login-btn send-verifyMobile"
          size="large"
          disabled={!afterMessage}
        >
          {i18n.t("verifyMobile")}
        </Button>
      </Form>
    </ModalLayout>

  );
};

export default PageMain;
