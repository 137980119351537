import i18n from "i18n";
import { useEffect, useState } from "react";
import { $get } from "services";

// 站台資料

export interface SettingInfo {
  Id: number;
  SiteName: string;	          // 站台名稱
  Currency: string;           // 幣別
  Language: string | null;    // 語系
  Type: number;               // 0: 現金版 | 1: 信用版
  Status: number;             // 狀態
  Logo1: string;              // Favicon
  Logo2: string;              // 324px X  70px圖片path
  WelcomePagePC: string;      // 500px X 500px圖片path
  WelcomePageMobile: string;  // 325px X 325px圖片path
  WhiteList: string;          // 系統白名單
  CreateDate: string;         // 建立時間
  PhonePrefix: string;        // 預設國碼
  TimeZone: string;           // 時區
  Domain: string | null;      // 網域
  CommissionModule: string;   // 會員等級返水制
}

const useSite = () => {
  const [init, setInit] = useState(false);
  const { data, mutate, isValidating } = $get({ url: 'common/sitedata/info', auth: false });
  const [info, setInfo] = useState<SettingInfo>({
    Id: 0,
    SiteName: '',
    Currency: '',
    Language: null,
    Type: 0,
    Status: 0,
    Logo1: '',
    Logo2: '',
    WelcomePagePC: '',
    WelcomePageMobile: '',
    WhiteList: '',
    CreateDate: '',
    PhonePrefix: '',
    TimeZone: 'Asia/Taipei',
    Domain: null,
    CommissionModule: '2'
  });

  useEffect(() => {
    if (data?.Data) {
      const _info = Object.assign(JSON.parse(JSON.stringify(data.Data)), {
        Logo1: data.Data.Logo1 ? `${data.Data.Logo1}?${Date.now()}` : '',
        Logo2: data.Data.Logo2 ? `${data.Data.Logo2}?${Date.now()}` : '',
        WelcomePagePC: data.Data.WelcomePagePC ? `${data.Data.WelcomePagePC}?${Date.now()}` : '',
        WelcomePageMobile: data.Data.WelcomePageMobile ? `${data.Data.WelcomePageMobile}?${Date.now()}` : '',
        // TimeZone: 'Asia/Ho_Chi_Minh',
      });
  
      setInfo(_info);
      // 已讀取資料
      setInit(true);

      window.serverZone = _info?.TimeZone;
      document.title = `${_info.SiteName} - ${i18n.t('agentBackend')}`;
    }
  }, [data])

  // 銀行code找name
  const { data: bankList } = $get({
    url: `api/bank/${info?.Currency}/list`,
    allow: !!(info?.Currency)
  })

  const bankCode2Name = (code: any) => {
    const bank = bankList?.Data.find((item: any) => item.Code === code)
    return bank?.Name || code || '-';
  }

  return {
    init,
    data: info,
    mutate,
    isValidating,
    isCashVersion: data?.Data?.Type === 0,
    bankCode2Name,
    isOurService: data?.Data?.CustomerServiceModule === "0"
  }
};

export default useSite;
