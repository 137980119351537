import i18n from "../i18n";

export const GATEWAY_CODE_WITHDRAW = {
  銀行卡: "WITHDRAW_GATE_BANK",
  虛擬貨幣: "WITHDRAW_GATE_CRYPTO",
}

export enum WALLET_DEPOSIT_TYPE {
  offlineBank,
  offlineCrypto,
  thirdDodo,
  thirdYspay,
  thirdBrilpay,
  thirdCob,
  thirdGoldtrain,
  thirdCrypto,
}

export const DEPOSIT_GATE_MAP = {
  DEPOSIT_GATE_BANK: 'bank',
  DEPOSIT_GATE_CVS: 'cvs',
  DEPOSIT_GATE_ATM: 'atm',
  DEPOSIT_GATE_QR: 'qr',
  DEPOSIT_GATE_DC: 'dc',
  DEPOSIT_GATE_CC: 'cc',
  DEPOSIT_GATE_CRYPTO: 'crypto',
}

export const WALLET_TRANSLATE: any = {
  DEPOSIT_GATE_BANK_OFFLINE: i18n.t("bankCardDepositing"),
  DEPOSIT_GATE_CRYPTO_OFFLINE: i18n.t("virtualCurrencyDepositing"),
  DEPOSIT_GATE_CVS: i18n.t("storePayment"),
  DEPOSIT_GATE_ATM: i18n.t("atm"),
  DEPOSIT_GATE_QR: i18n.t("qrScanning"),
  DEPOSIT_GATE_DC: i18n.t("directConnection"),
  DEPOSIT_GATE_CC: i18n.t("creditCard"),
  DEPOSIT_GATE_CRYPTO: i18n.t("virtualCurrencyDepositingThird"),
  WITHDRAW_GATE_BANK: i18n.t("bankCard"),
  WITHDRAW_GATE_CRYPTO: i18n.t("virtualCurrency")
}
