import { Button, Form, Input, message } from "antd";
import { LayoutNav, ModalLayout } from "components/layout.component";
import i18n from "i18n";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { $post } from "services";

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const [loading, isLoading] = useState(false);

  const onSubmit = async (formData: any) => {
    if (formData.NewPassword !== formData.RepeatPassword) {
      message.error(i18n.t('passwordConfirmationError'));
      return
    }
    const send = {
      CurrentPassword: formData.CurrentPassword,
      NewPassword: formData.NewPassword,
      RepeatPassword: formData.RepeatPassword,
    };
    $post({
      url: 'api/agent/password/update',
      send,
      success: () => {
        message.success(i18n.t('modifySuccess'));
        navigate('/');
      }
    }, isLoading);
  };

  return (
    <>
      <LayoutNav hidden />
      <ModalLayout paddingBottom='100px'>
        <Form size="middle" onFinish={onSubmit}>
          <Form.Item className="login-title" style={{ textAlign: 'center', color: '#666' }}>
            <p>{i18n.t('modifyPassword')}</p>
          </Form.Item>
          <Form.Item
            name="CurrentPassword"
            rules={[{ required: true, message: `${i18n.t('currentPasswordRequired')}` }]}
          >
            <Input.Password placeholder={`${i18n.t('currentPassword')}`} />
          </Form.Item>
          <Form.Item
            className="mt-1"
            name="NewPassword"
            rules={[{ required: true, message: `${i18n.t('newPasswordRequired')}` }]}
          >
            <Input.Password placeholder={`${i18n.t('newPassword')}`} />
          </Form.Item>
          <Form.Item
            className="mt-1"
            name="RepeatPassword"
            rules={[
              { required: true, message: `${i18n.t('confirmNewPasswordRequired')}` },
            ]}
          >
            <Input.Password placeholder={`${i18n.t('confirmNewPassword')}`} />
          </Form.Item>
          <Form.Item className="center mt-1 login-btn-wrap" name="modify-password">
            <Button
              htmlType="submit"
              loading={loading}
              className="w-full login-btn"
              style={{ background: '#DABF8D' }}
              size="large"
            >
              {i18n.t('confirm')}
            </Button>
          </Form.Item>
        </Form>
      </ModalLayout>
    </>
  );
};

export default PageMain;
