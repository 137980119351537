import useSite from "hooks/site.hook";
import { Button, Col, Descriptions, Form, Image, Input, Modal, Row, Select, Spin, message } from "antd";
import { useEffect, useState } from "react";
import { $get, $post } from "services";
import { UploadFile } from 'antd/es/upload';
import i18n from "i18n";
import { RESPONSE_CODE } from "../constants/response";
import { $mobile, toFormatNumber, verify } from "utils/common";
import { CopyButton, UploadImage } from "./layout.component";
import { DEPOSIT_GATE_MAP, WALLET_DEPOSIT_TYPE, WALLET_TRANSLATE } from "constants/gateway";
import { ENABLE } from "enum/state";

// 線下: 銀行
export const PopupBankCard = ({ isOpen, close }: any) => {
  const { data: $s } = useSite();
  const [form] = Form.useForm();
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  // 銀行卡列表
  const [depositMerchantList, setDepositMerchantList] = useState<any>({});
  const { data: _depositMerchantList } = $get({
    url: 'api/deposit/merchant/list',
    params: { GatewayCode: 'DEPOSIT_GATE_BANK_OFFLINE' }
  })
  useEffect(() => {
    if (_depositMerchantList) {
      const obj: any = {};

      _depositMerchantList.Data.forEach((item: any) => {
        obj[item.DepositMerchantId] = { ...obj[item.DepositMerchantId], ...item }
      })
      setDepositMerchantList(obj);
    }
  }, [_depositMerchantList]);

  const [currentDepositId, setCurrentDepositId] = useState(0);
  const updateBankcard = (value: any) => {
    // const bankData = _depositMerchantList.Data.find((item: any) => item.DepositMerchantId === value);
    // setBankcard(bankData);
    setCurrentDepositId(value);
  }

  const [bankcard, setBankcard] = useState<any>({});
  const { data: one, isValidating } = $get({
    url: 'api/deposit/merchant/one',
    params: { Id: currentDepositId },
    allow: !!currentDepositId
  })
  useEffect(() => {
    if (one) {
      setBankcard({
        ...one.Data,
        ...one.Data.Offline
      })
    }
  }, [one]);
  const [loading, setLoading] = useState(false);
  // 送出
  const handleSubmit = (formData: any) => {
    setLoading(true);

    $post({
      url: 'api/deposit/create/offline',
      send: {
        OfflineDepositId: bankcard.OfflineDepositId,
        DepositMerchantId: formData.DepositMerchantId,
        Amount: formData.Amount,
        Remark: formData.Remark,
        Photo: imageData[0] ? [imageData[0].url] : [],
      },
      success: () => {
        message.success(i18n.t('applySuccess'));
        setLoading(false);
        handleCancel();
      },
      showMessage: false,
      fail: (response: any) => {
        setLoading(false);
        if (response.Message === 'APPLY_AMOUNT_OVER_LIMIT') {
          message.error(`${i18n.t('amountRequired')} ${bankcard.Minimum} ~ ${bankcard.Maximum || i18n.t('unlimited')}`)
        }
        else if (response.Message === 'WALLET_BALANCE_MAXIMUM_LIMIT') {
          message.error(i18n.t('agentBalanceLimitReached'))
        }
        else {
          const msg = RESPONSE_CODE[response.Message as keyof typeof RESPONSE_CODE];
          message.error(msg || i18n.t('processingFailed'));
        }
      }
    })
  }

  const handleCancel = () => {
    close();
    form.resetFields();
    setBankcard({});
    setImageData([]);
    setCurrentDepositId(0);
  }

  // 顯示欄位
  const descriptionsItem = (label: string, text: string, copy: boolean = true) => (
    text
    ? <Descriptions.Item label={i18n.t(label)}>
      <Row align="middle" justify="space-between">
        <Col>{text}</Col>
        {copy && <Col><CopyButton text={text} /></Col>}
      </Row>
    </Descriptions.Item>
    : <></>
  )

  return (
    <Modal
      title={i18n.t('bankCardDepositing')}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={$mobile ? '80vw' : '900px'}
      footer={
        <Row justify="center" gutter={[12, 12]} className="mt-1" style={{ paddingRight: '16px' }}>
          <Col><Button key="cancel" onClick={handleCancel}>{i18n.t('cancel')}</Button></Col>
          <Col><Button key="confirm" type="primary" disabled={loading} onClick={form.submit}>{i18n.t('confirm')}</Button></Col>
        </Row>
      }
    >
      <Spin spinning={loading}>
        <Form layout="vertical" form={form} onFinish={handleSubmit} style={{ maxHeight: '80vh', overflowY: 'auto' }}>
          <Row gutter={[12, 12]} className="w-full">
            <Col span={$mobile ? 24 : 12}>
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <Form.Item name="DepositMerchantId" label={i18n.t('bankName')} rules={[{ required: true }]}>
                    <Select
                      className="w-full"
                      placeholder={`${i18n.t('pleaseSelect')}`}
                      options={
                        Object.keys(depositMerchantList).map((k: any) => ({
                          value: k, label: depositMerchantList[k].CustomName
                        }))
                      }
                      onChange={updateBankcard}
                    />
                  </Form.Item>
                </Col>
                {/* 基本資料 */}
                <Col span={24}>
                  <Spin spinning={isValidating}>
                    <Descriptions size="small" layout={$mobile ? 'vertical' : 'horizontal'} column={1} bordered
                      labelStyle={{ fontSize: 12, fontWeight: 600, minWidth: 170, width: 170 }}>
                      {descriptionsItem('bankName', bankcard.BankName)}
                      {descriptionsItem('bankAccount', bankcard.Account)}
                      {descriptionsItem('bankAccountName', bankcard.Name)}
                      {descriptionsItem('province', bankcard.State)}
                      {descriptionsItem('city', bankcard.City)}
                      {descriptionsItem('branchName', bankcard.Branch)}
                      {descriptionsItem('phoneNumber', `${bankcard.PhonePrefix || ''}${bankcard.PhoneNumber || ''}`)}
                      {descriptionsItem('remark', bankcard.FnDescription, false)}
                      {bankcard.QR_Code ? 
                      <Descriptions.Item label={i18n.t('qrCode')}>
                        <Image src={bankcard.QR_Code} width={80} height={80} />
                      </Descriptions.Item> 
                      : <></>}
                      </Descriptions>
                  </Spin>
                </Col>
              </Row>
            </Col>
            <Col span={$mobile ? 24 : 12}>
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <Form.Item name="Amount" label={i18n.t('amount')} rules={verify({ point: 4, zero: false })}>
                    <Input placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                  <div className="color-05 size-10">
                    {currentDepositId ? `${i18n.t('single')}: ${bankcard.Minimum || 0} - ${bankcard.Maximum || i18n.t('unlimited')}` : ''}
                  </div>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="Photo" label={i18n.t('uploadCertification')} 
                      rules={[{ required: !imageData[0], message: `${i18n.t('uploadImage')}` }]}>
                      <UploadImage
                        name="Photo"
                        url={'/upload/images/closed/deposit'}
                        imageData={imageData}
                        setImageData={setImageData}
                        form={form}
                      />
                    </Form.Item>
                  </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

// 線下: 虛擬
export const PopupCrypto = ({ isOpen, close }: any) => {
  const [form] = Form.useForm();
  const [cryptoType, setCryptoType] = useState<any | null>();
  const { data: cryptoList } = $get({ url: 'api/deposit/offline/crypto/list' });
  const [currentMerchant, setCurrentMerchant] = useState<any>({});
  const [amount, setAmount] = useState(0);
  const { data: MerchantList } = $get({
    url: 'api/deposit/offline/crypto/merchant/list',
    params: { Crypto: cryptoType },
    allow: !!cryptoType
  })
  const [loading, setLoading] = useState(false);
  // 送出
  const handleSubmit = (formData: any) => {
    setLoading(true);
    $post({
      url: 'api/deposit/create/offline',
      send: {
        OfflineDepositId: currentMerchant.Id,
        DepositMerchantId: formData.DepositMerchantId,
        Amount: formData.Amount,
        Remark: '',
        Photo: [],
      },
      success: () => {
        message.success(i18n.t('applySuccess'));
        setLoading(false);
        handleCancel();
      },
      showMessage: false,
      fail: (response: any) => {
        setLoading(false);
        if (response.Message === 'APPLY_AMOUNT_OVER_LIMIT') {
          message.error(`${i18n.t('amountRequired')} ${currentMerchant.Minimum} ~ ${currentMerchant.Maximum || i18n.t('unlimited')}`)
        }
        else if (response.Message === 'WALLET_BALANCE_MAXIMUM_LIMIT') {
          message.error(i18n.t('agentBalanceLimitReached'))
        }
        else {
          const msg = RESPONSE_CODE[response.Message as keyof typeof RESPONSE_CODE];
          message.error(msg || i18n.t('processingFailed'));
        }
      }
    })
  }

  const handleCancel = () => {
    close();
    form.resetFields();
    // setPaymentMethod(null);
    setCryptoType(null)
    setCurrentMerchant({});
    // setImageData([]);
  };

  return (
    <Modal
      title={i18n.t('virtualCurrencyDepositing')}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col><Button className="mt-1" key="cancel" onClick={handleCancel}>{i18n.t('cancel')}</Button></Col>
          <Col><Button className="mt-1" key="confirm" type="primary" disabled={loading} loading={false} onClick={form.submit}>{i18n.t('confirm')}</Button></Col>
        </Row>
      }
    >
      <Spin spinning={loading}>
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Row gutter={[12, 12]}>
            {/* 幣種 */}
            <Col span={24}>
              <Form.Item name="Crypto" label={i18n.t('currency')} rules={[{ required: true }]}>
                <Select
                  className="w-full"
                  placeholder={`${i18n.t('pleaseSelect')}`}
                  options={cryptoList?.Data?.map((item: any) => {
                    return { value: item.Crypto, label: item.Crypto }
                  })}
                  onChange={val => {
                    setCryptoType(val);
                    form.setFieldValue('CryptoType', null);
                    form.setFieldValue('DepositMerchantId', null);
                    setCurrentMerchant({});
                  }}
                />
              </Form.Item>
            </Col>
            {/* 網路類型 */}
            <Col span={24} >
              <Form.Item name="CryptoType" label={i18n.t('networkType')} rules={[{ required: true }]}>
                <Select
                  className="w-full"
                  placeholder={`${i18n.t('pleaseSelect')}`}
                  options={
                    MerchantList?.Data
                      ?.filter((item: any, i: any) => (MerchantList.Data.map((_: any) => _.CryptoType).indexOf(item.CryptoType)) === i)
                      ?.filter((item: any) => item.Status === ENABLE.enabled)
                      .map((item: any) => {
                        return { value: item.CryptoType, label: item.CustomName }
                      })
                  }
                  onChange={val => {
                    form.setFieldValue('DepositMerchantId', null);
                    setCurrentMerchant({});
                  }}
                />
              </Form.Item>
              <Form.Item className="display-none" name="MerchantId" />
            </Col>
            {/* 地址 */}
            <Col span={24} >
              <Form.Item name="DepositMerchantId" label={i18n.t('userAddress')} rules={[{ required: true }]}>
                <Select
                  className="w-full"
                  placeholder={`${i18n.t('pleaseSelect')}`}
                  options={
                    MerchantList?.Data?.filter((item: any) => item.CryptoType === form.getFieldValue('CryptoType'))
                      .map((item: any) => {
                        return { value: item.DepositMerchantId, label: item.CryptoAddress }
                      })
                  }
                  onChange={val => {
                    setCurrentMerchant(MerchantList?.Data?.find((item: any) => item.DepositMerchantId === val))
                  }}
                />
              </Form.Item>
              <Form.Item className="display-none" name="MerchantId" />
            </Col>
            {currentMerchant.QR_Code &&
            <Col span={24} >
              <Image className="center" src={currentMerchant.QR_Code} width={100} height={100} />
            </Col>
            }
            <Col span={24}>
              <Form.Item name="Amount" label={i18n.t('amount')} rules={verify({ point: 4, zero: false })}>
                <Input placeholder={`${i18n.t('inputData')}`} onChange={e => setAmount(Number(e.target.value))} />
              </Form.Item>
              <div className="color-05 size-12">
                {currentMerchant.Maximum ? `${i18n.t('single')}: ${currentMerchant.Minimum} - ${currentMerchant.Maximum}` : ''}
              </div>
              <div className="color-05 size-12">
                {i18n.t('exchangeRate')}: <span className="color-07">≈ {toFormatNumber(Number(amount) / currentMerchant.ExchangeRate, 2, 'ceil')} {cryptoType}</span>
              </div>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

// 三方: 彈窗
export const PopupThird = ({ isOpen, close, currentManufacturer }: any) => {
  const gateway = DEPOSIT_GATE_MAP[currentManufacturer.Code as keyof typeof DEPOSIT_GATE_MAP];

  const [loading, setLoading] = useState(false);
  const [thirdDisplay, setThirdDisplay] = useState(WALLET_DEPOSIT_TYPE.thirdDodo);
  const [depositMerchantCode, setDepositMerchantCode] = useState<string | null>(null);
  const [cryptoCoin, setCryptoCoin] = useState<any>();
  const [cryptoNetwork, setCryptoNetwork] = useState<any>();
  const [send, setSend] = useState<any>({});
  const [maximum, setMaximum] = useState('');
  const [minimum, setMinimum] = useState('');
  const [exchangeRate, setExchangeRate] = useState(1);
  const [form] = Form.useForm();
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (isOpen) {
      // 三方虛擬
      if (currentManufacturer.Code === 'DEPOSIT_GATE_CRYPTO'){
        setThirdDisplay(WALLET_DEPOSIT_TYPE.thirdCrypto);
        setSend({
        })
      } else {
        // 三方銀行
        switch (depositMerchantCode) {
          case 'yspay':
            setThirdDisplay(WALLET_DEPOSIT_TYPE.thirdYspay);
            setSend({
              BankCode: undefined,
            })
            break;
          case 'brilpay':
            setThirdDisplay(WALLET_DEPOSIT_TYPE.thirdBrilpay);
            setSend({
              BankNum: undefined,
            })
            break;
          case 'cob':
            setThirdDisplay(WALLET_DEPOSIT_TYPE.thirdCob);
            setSend({
              BankCode: undefined,
              BankAccount: undefined,
            })
            break;
          case 'goldtrain':
            setThirdDisplay(WALLET_DEPOSIT_TYPE.thirdGoldtrain);
            setSend({
            })
            break;
          default:
            setThirdDisplay(WALLET_DEPOSIT_TYPE.thirdDodo);
            setSend({
            })
            break;
        }
      }
    }
  }, [isOpen, depositMerchantCode]);

  const { data: $s } = useSite();

  const { data: merchantList } = $get({
    url: 'api/deposit/merchant/list',
    params: { GatewayCode: currentManufacturer.Code },
    allow: !!currentManufacturer.Code
  })

  const { data: cryptoCoinList } = $get({
    url: 'api/deposit/crypto/list',
  })

  const { data: cryptoNetworkList } = $get({
    url: 'api/deposit/crypto/type/list',
    params: { Crypto: cryptoCoin },
    allow: !!(cryptoCoin)
  })

  const { data: cryptoMerchantList } = $get({
    url: 'api/deposit/crypto/type/merchant/list',
    params: { Crypto: cryptoCoin, CryptoType: cryptoNetwork },
    allow: !!(cryptoCoin && cryptoNetwork)
  })

  // 銀行名稱列表
  const { data: getBankNameList } = $get({
    url: `api/bank/${$s?.Currency}/list`,
    allow: !!($s?.Currency)
  })

  // 更換渠道
  const depositMerchantIdChanged = (id: number, type: string) => {
    if (id) {
      const list = type === 'bank' ? merchantList : cryptoMerchantList;
      const selectedMerchant = list.Data.find((item: any) => item[type === 'bank' ? 'DepositMerchantId' : 'MerchantId'] === Number(id));
      if (selectedMerchant) {
        setMaximum(selectedMerchant.Maximum);
        setMinimum(selectedMerchant.Minimum);
        setExchangeRate(selectedMerchant.ExchangeRate);
        setDepositMerchantCode(selectedMerchant.Code);
      }
    }
  }
  
  const cryptoCoinChanged = (value: string) => {
    setCryptoCoin(value);
  }

  const cryptoNetworkChanged = (value: string) => {
    setCryptoNetwork(value);
  }

  // 送出
  const handleSubmit = (formData: any) => {
    setLoading(true);

    Object.keys(send).forEach(key => {
      send[key] = formData[key];
    });
    $post({
      url: `api/deposit/create/${gateway}/${depositMerchantCode}/merchant`,
      send: {
        Amount: parseFloat(formData.Amount),
        DepositMerchantId: formData.DepositMerchantId,
        ...send,
      },
      showMessage: false,
      success: (response: any) => {
        message.success(i18n.t('applySuccess'));
        setLoading(false);
        handleCancel();
        window.open(response?.Url);
      },
      fail: (response: any) => {
        setLoading(false);
        if (response.Message === 'APPLY_AMOUNT_OVER_LIMIT') {
          message.error(`${i18n.t('amountRequired')}${minimum}~${maximum || i18n.t('unlimited')}`);
        }
        else if (response.Message === 'WALLET_BALANCE_MAXIMUM_LIMIT') {
          message.error(i18n.t('agentBalanceLimitReached'));
        }
        else {
          const msg = RESPONSE_CODE[response.Message as keyof typeof RESPONSE_CODE];
          message.error(msg || i18n.t('processingFailed'));
        }
      }
    })
  }
  
  const handleCancel = () => {
    close();
    form.resetFields();
    setMaximum('');
    setMinimum('');
    setExchangeRate(1);
    setDepositMerchantCode(null);
    setCryptoCoin(null);
    setCryptoNetwork(null);
    setSend({});
    setThirdDisplay(WALLET_DEPOSIT_TYPE.thirdDodo);
    setAmount(0);
  };

  return (
    <Modal
      title={WALLET_TRANSLATE[currentManufacturer.Code]}
      open={isOpen}
      onCancel={handleCancel}
      centered
      width={450}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col><Button className="mt-1" key="cancel" onClick={handleCancel}>{i18n.t('cancel')}</Button></Col>
          <Col><Button className="mt-1" key="confirm" type="primary" disabled={loading} loading={false} onClick={form.submit}>{i18n.t('confirm')}</Button></Col>
        </Row>
      }
    >
      <Spin spinning={loading}>
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Row gutter={[12, 12]}>
            {/* 369(四通) */}
            {thirdDisplay === WALLET_DEPOSIT_TYPE.thirdDodo && 
              <ThirdDodo minimum={minimum} maximum={maximum} merchantList={merchantList} depositMerchantIdChanged={depositMerchantIdChanged} />}
            {/* yspay(越南) */}
            {thirdDisplay === WALLET_DEPOSIT_TYPE.thirdYspay && 
              <ThirdYspay minimum={minimum} maximum={maximum} merchantList={merchantList} depositMerchantIdChanged={depositMerchantIdChanged} getBankNameList={getBankNameList} />}
            {/* 卓越 */}
            {thirdDisplay === WALLET_DEPOSIT_TYPE.thirdBrilpay && 
              <ThirdBrilpay minimum={minimum} maximum={maximum} merchantList={merchantList} depositMerchantIdChanged={depositMerchantIdChanged} />}
            {/* cob */}
            {thirdDisplay === WALLET_DEPOSIT_TYPE.thirdCob && 
              <ThirdCob minimum={minimum} maximum={maximum} merchantList={merchantList} depositMerchantIdChanged={depositMerchantIdChanged} getBankNameList={getBankNameList} />}
            {/* goldtrain(黃金列車) */}
            {thirdDisplay === WALLET_DEPOSIT_TYPE.thirdGoldtrain && 
              <ThirdGoldtrain minimum={minimum} maximum={maximum} merchantList={merchantList} depositMerchantIdChanged={depositMerchantIdChanged} />}
            {/* TopPay(U收付)(虛擬) */}
            {thirdDisplay === WALLET_DEPOSIT_TYPE.thirdCrypto && 
              <ThirdCrypto minimum={minimum} maximum={maximum} merchantList={cryptoMerchantList} depositMerchantIdChanged={depositMerchantIdChanged}
              cryptoCoinList={cryptoCoinList} cryptoNetworkList={cryptoNetworkList} cryptoCoinChanged={cryptoCoinChanged} cryptoNetworkChanged={cryptoNetworkChanged} 
              exchangeRate={exchangeRate} cryptoType={cryptoCoin} amount={amount} setAmount={setAmount}
              />}
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

// 三方: 彈窗: 369(四通)
export const ThirdDodo = ({ minimum, maximum, merchantList, depositMerchantIdChanged }: any) => {
  return <>
    {/* 渠道 */}
    <Col span={24}>
      <Form.Item name="DepositMerchantId" label={i18n.t('depositMerchant')} rules={[{ required: true }]}>
        <Select
          className="w-full"
          placeholder={`${i18n.t('pleaseSelect')}`}
          options={merchantList?.Data.map((item: any) => {
            return { value: item.DepositMerchantId, label: i18n.t(item.CustomName) }
          })}
          onChange={v => depositMerchantIdChanged(v, 'bank')}
        />
      </Form.Item>
    </Col>
    {/* 金額 */}
    <Col span={24}>
      <Form.Item name="Amount" label={i18n.t('amount')} rules={verify({ point: 4, zero: false })}>
        <Input placeholder={`${i18n.t('inputData')}`} />
      </Form.Item>
      <div className="color-05 size-10">
        {`${i18n.t('single')}: ${minimum} - ${maximum || i18n.t('unlimited')}`}
      </div>
    </Col>
  </>
}

// 三方: 彈窗: yspay(越南)
export const ThirdYspay = ({ minimum, maximum, merchantList, depositMerchantIdChanged, getBankNameList }: any) => {
  return <>
    {/* 渠道 */}
    <Col span={24}>
      <Form.Item name="DepositMerchantId" label={i18n.t('depositMerchant')} rules={[{ required: true }]}>
        <Select
          className="w-full"
          placeholder={`${i18n.t('pleaseSelect')}`}
          options={merchantList?.Data.map((item: any) => {
            return { value: item.DepositMerchantId, label: i18n.t(item.CustomName) }
          })}
          onChange={v => depositMerchantIdChanged(v, 'bank')}
        />
      </Form.Item>
    </Col>
    {/* 下拉銀行名稱 */}
    <Col span={24}>
      <Form.Item name="BankCode" label={i18n.t('bankName')} rules={[{ required: true }]}>
        <Select
          className="w-full"
          showSearch
          filterOption={(input, option) =>
            `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase()) ||
            `${(option?.value ?? '')}`.toLowerCase().includes(input.toLowerCase())
          }
          placeholder={`${i18n.t('inputData')}`}
          options={getBankNameList?.Data.map((item: any) => {
            return { value: item.Code, label: i18n.t(item.Name) }
          })}
        />
      </Form.Item>
    </Col>
    {/* 金額 */}
    <Col span={24}>
      <Form.Item name="Amount" label={i18n.t('amount')} rules={verify({ point: 4, zero: false })}>
        <Input placeholder={`${i18n.t('inputData')}`} />
      </Form.Item>
      <div className="color-05 size-10">
        {`${i18n.t('single')}: ${minimum} - ${maximum || i18n.t('unlimited')}`}
      </div>
    </Col>
  </>
}

// 三方: 彈窗: 卓越
export const ThirdBrilpay = ({ minimum, maximum, merchantList, depositMerchantIdChanged }: any) => {
  return <>
    {/* 渠道 */}
    <Col span={24}>
      <Form.Item name="DepositMerchantId" label={i18n.t('depositMerchant')} rules={[{ required: true }]}>
        <Select
          className="w-full"
          placeholder={`${i18n.t('pleaseSelect')}`}
          options={merchantList?.Data.map((item: any) => {
            return { value: item.DepositMerchantId, label: i18n.t(item.CustomName) }
          })}
          onChange={v => depositMerchantIdChanged(v, 'bank')}
        />
      </Form.Item>
    </Col>
    {/* 手打銀行帳號 */}
    <Col span={24}>
      <Form.Item name="BankNum" label={i18n.t('bankAccount')} rules={[{ required: true }, { validator: (d, val) => {
        const regex = new RegExp('^[0-9]+$');
        if (val && !regex.test(val)) return Promise.reject(i18n.t('needBeNumbers09'));
        else return Promise.resolve();
      }}]}>
        <Input placeholder={`${i18n.t('inputData')}`} />
      </Form.Item>
    </Col>
    {/* 金額 */}
    <Col span={24}>
      <Form.Item name="Amount" label={i18n.t('amount')} rules={verify({ point: 4, zero: false })}>
        <Input placeholder={`${i18n.t('inputData')}`} />
      </Form.Item>
      <div className="color-05 size-10">
        {`${i18n.t('single')}: ${minimum} - ${maximum || i18n.t('unlimited')}`}
      </div>
    </Col>
  </>
}

// 三方: 彈窗: cob
export const ThirdCob = ({ minimum, maximum, merchantList, depositMerchantIdChanged, getBankNameList }: any) => {
  return <>
    {/* 渠道 */}
    <Col span={24}>
      <Form.Item name="DepositMerchantId" label={i18n.t('depositMerchant')} rules={[{ required: true }]}>
        <Select
          className="w-full"
          placeholder={`${i18n.t('pleaseSelect')}`}
          options={merchantList?.Data.map((item: any) => {
            return { value: item.DepositMerchantId, label: i18n.t(item.CustomName) }
          })}
          onChange={v => depositMerchantIdChanged(v, 'bank')}
        />
      </Form.Item>
    </Col>
    {/* 下拉銀行名稱 */}
    <Col span={24}>
      <Form.Item name="BankCode" label={i18n.t('bankName')} rules={[{ required: true }]}>
        <Select
          className="w-full"
          showSearch
          filterOption={(input, option) =>
            `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase()) ||
            `${(option?.value ?? '')}`.toLowerCase().includes(input.toLowerCase())
          }
          placeholder={`${i18n.t('inputData')}`}
          options={getBankNameList?.Data.map((item: any) => {
            return { value: item.Code, label: i18n.t(item.Name) }
          })}
        />
      </Form.Item>
    </Col> 
    {/* 手打銀行帳號 */}
    <Col span={24}>
      <Form.Item name="BankAccount" label={i18n.t('bankAccount')} rules={[{ required: true }, { validator: (d, val) => {
        const regex = new RegExp('^[0-9]+$');
        if (val && !regex.test(val)) return Promise.reject(i18n.t('needBeNumbers09'));
        else return Promise.resolve();
      }}]}>
        <Input placeholder={`${i18n.t('inputData')}`} />
      </Form.Item>
    </Col>
    {/* 金額 */}
    <Col span={24}>
      <Form.Item name="Amount" label={i18n.t('amount')} rules={verify({ point: 4, zero: false })}>
        <Input placeholder={`${i18n.t('inputData')}`} />
      </Form.Item>
      <div className="color-05 size-10">
        {`${i18n.t('single')}: ${minimum} - ${maximum || i18n.t('unlimited')}`}
      </div>
    </Col>
  </>
}

// 三方: 彈窗: goldtrain(黃金列車)
export const ThirdGoldtrain = ({ minimum, maximum, merchantList, depositMerchantIdChanged }: any) => {
  return <>
    {/* 渠道 */}
    <Col span={24}>
      <Form.Item name="DepositMerchantId" label={i18n.t('depositMerchant')} rules={[{ required: true }]}>
        <Select
          className="w-full"
          placeholder={`${i18n.t('pleaseSelect')}`}
          options={merchantList?.Data.map((item: any) => {
            return { value: item.DepositMerchantId, label: i18n.t(item.CustomName) }
          })}
          onChange={v => depositMerchantIdChanged(v, 'bank')}
        />
      </Form.Item>
    </Col>
    {/* 金額 */}
    <Col span={24}>
      <Form.Item name="Amount" label={i18n.t('amount')} rules={verify({ point: 4, zero: false })}>
        <Input placeholder={`${i18n.t('inputData')}`} />
      </Form.Item>
      <div className="color-05 size-10">
        {`${i18n.t('single')}: ${minimum} - ${maximum || i18n.t('unlimited')}`}
      </div>
    </Col>
  </>
}

// 三方: 彈窗: TopPay(U收付)(虛擬)
export const ThirdCrypto = ({ minimum, maximum, merchantList, depositMerchantIdChanged, cryptoCoinList, cryptoNetworkList, cryptoCoinChanged, cryptoNetworkChanged, exchangeRate, cryptoType, amount, setAmount }: any) => {
  return <>
    {/* 幣種 */}
    <Col span={24}>
      <Form.Item name="Crypto" label={i18n.t('currency')} rules={[{ required: true }]}>
        <Select
          className="w-full"
          placeholder={`${i18n.t('pleaseSelect')}`}
          options={cryptoCoinList?.Data?.map((item: any) => {
            return { value: item.Crypto, label: item.Crypto }
          })}
          onChange={cryptoCoinChanged}
        />
      </Form.Item>
    </Col>
    {/* 網路類型 */}
    <Col span={24} >
      <Form.Item name="CryptoType" label={i18n.t('networkType')} rules={[{ required: true }]}>
        <Select
          className="w-full"
          placeholder={`${i18n.t('pleaseSelect')}`}
          options={
            cryptoNetworkList?.Data?.map((item: any) => {
              return { value: item.CryptoType, label: item.CryptoType }
            })
          }
          onChange={cryptoNetworkChanged}
        />
      </Form.Item>
      <Form.Item className="display-none" name="MerchantId" />
    </Col>
    {/* 渠道 */}
    <Col span={24}>
      <Form.Item name="DepositMerchantId" label={i18n.t('thirdPartName')} rules={[{ required: true }]}>
        <Select
          className="w-full"
          placeholder={`${i18n.t('pleaseSelect')}`}
          options={merchantList?.Data.map((item: any) => {
            return { value: item.MerchantId, label: item.Name }
          })}
          onChange={v => depositMerchantIdChanged(v, 'crypto')}
        />
      </Form.Item>
    </Col>
    {/* 金額 */}
    <Col span={24}>
      <Form.Item name="Amount" label={i18n.t('amount')} rules={verify({ point: 4, zero: false })}>
        <Input placeholder={`${i18n.t('inputData')}`} onChange={e => setAmount(Number(e.target.value))} />
      </Form.Item>
      <div className="color-05 size-12">
        {`${i18n.t('single')}: ${minimum} - ${maximum || i18n.t('unlimited')}`}
      </div>
      <div className="color-05 size-12">
        {i18n.t('exchangeRate')}: <span className="color-07">≈ {toFormatNumber(Number(amount) / exchangeRate, 2, 'ceil')} {cryptoType}</span>
      </div>
    </Col>
  </>
}
