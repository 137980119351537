const main = function() {

  const Data = [
    {
      "Id": 88862,
      "Code": "CEC72976A0A267E797FE4BA62CDA9B53",
      "Level": 0,
      "AgentAccount": "week00",
      "Role": 1,
      "OwnerId": 13,
      "KeyId": "1012",
      "OperatorAccount": null,
      "TargetAccount": "mockkkkkkk data",
      "Amount": 666.6666,
      "BeforeAmount": 666666.6666,
      "AfterAmount": 66666666.6666,
      "Remark": "假資料假資料假資料假資料假資料",
      "CreateDate": "2024-12-12 16:58:44",
      "UpdateDate": "2024-12-12 16:58:44"
    },
    {
      "Id": 88861,
      "Code": "CC6681BCA92A23A84298893C8CC0EF75",
      "Level": 0,
      "AgentAccount": "week00",
      "Role": 1,
      "OwnerId": 13,
      "KeyId": "1012",
      "OperatorAccount": null,
      "TargetAccount": null,
      "Amount": 100,
      "BeforeAmount": 4718285.5181,
      "AfterAmount": 4718385.5181,
      "Remark": "123",
      "CreateDate": "2024-12-26 16:52:20",
      "UpdateDate": "2024-12-26 16:52:20"
    },
    {
      "Id": 88860,
      "Code": "48C57840CBDC33EAFB2870B07A136F75",
      "Level": 0,
      "AgentAccount": "week00",
      "Role": 1,
      "OwnerId": 13,
      "KeyId": "1012",
      "OperatorAccount": null,
      "TargetAccount": null,
      "Amount": 100,
      "BeforeAmount": 4718185.5181,
      "AfterAmount": 4718285.5181,
      "Remark": "123",
      "CreateDate": "2024-12-26 16:51:54",
      "UpdateDate": "2024-12-26 16:51:54"
    },
    {
      "Id": 88859,
      "Code": "FEF0E77F85F14CF2762281F4FAD2C31E",
      "Level": 0,
      "AgentAccount": "week00",
      "Role": 1,
      "OwnerId": 13,
      "KeyId": "1011",
      "OperatorAccount": null,
      "TargetAccount": null,
      "Amount": -100,
      "BeforeAmount": 4718285.5181,
      "AfterAmount": 4718185.5181,
      "Remark": "",
      "CreateDate": "2024-12-26 16:51:32",
      "UpdateDate": "2024-12-26 16:51:32"
    },
    {
      "Id": 88858,
      "Code": "FDD1D5695B1403576401B51216D66830",
      "Level": 0,
      "AgentAccount": "week00",
      "Role": 1,
      "OwnerId": 13,
      "KeyId": "1011",
      "OperatorAccount": null,
      "TargetAccount": null,
      "Amount": -100,
      "BeforeAmount": 4718385.5181,
      "AfterAmount": 4718285.5181,
      "Remark": "",
      "CreateDate": "2024-12-26 16:51:27",
      "UpdateDate": "2024-12-26 16:51:27"
    },
    {
      "Id": 88857,
      "Code": "868BFE6BF9DCC2468C73863A1C8A7871",
      "Level": 0,
      "AgentAccount": "week00",
      "Role": 1,
      "OwnerId": 13,
      "KeyId": "1011",
      "OperatorAccount": null,
      "TargetAccount": null,
      "Amount": -100,
      "BeforeAmount": 4718485.5181,
      "AfterAmount": 4718385.5181,
      "Remark": "",
      "CreateDate": "2024-12-26 16:51:24",
      "UpdateDate": "2024-12-26 16:51:24"
    },
    {
      "Id": 88856,
      "Code": "EC27CBA7DFC26DBBE049A6BB912C0C21",
      "Level": 0,
      "AgentAccount": "week00",
      "Role": 1,
      "OwnerId": 13,
      "KeyId": "1011",
      "OperatorAccount": null,
      "TargetAccount": null,
      "Amount": -100,
      "BeforeAmount": 4718585.5181,
      "AfterAmount": 4718485.5181,
      "Remark": "",
      "CreateDate": "2024-12-26 16:51:21",
      "UpdateDate": "2024-12-26 16:51:21"
    },
    {
      "Id": 88855,
      "Code": "5392277F468758E3304032987A1308A5",
      "Level": 0,
      "AgentAccount": "week00",
      "Role": 1,
      "OwnerId": 13,
      "KeyId": "1011",
      "OperatorAccount": null,
      "TargetAccount": null,
      "Amount": -100,
      "BeforeAmount": 4718685.5181,
      "AfterAmount": 4718585.5181,
      "Remark": "",
      "CreateDate": "2024-12-26 16:51:17",
      "UpdateDate": "2024-12-26 16:51:17"
    },
    {
      "Id": 88496,
      "Code": "90C81D811CBBF176CB5FD12D943AB64B",
      "Level": 0,
      "AgentAccount": "week00",
      "Role": 1,
      "OwnerId": 13,
      "KeyId": "1006",
      "OperatorAccount": null,
      "TargetAccount": null,
      "Amount": 100,
      "BeforeAmount": 4718585.5181,
      "AfterAmount": 4718685.5181,
      "Remark": "",
      "CreateDate": "2024-12-20 11:10:04",
      "UpdateDate": "2024-12-20 11:10:04"
    },
    {
      "Id": 88456,
      "Code": "3E9EF549FF68AE310E2F6C2D2373B1D7",
      "Level": 0,
      "AgentAccount": "week00",
      "Role": 1,
      "OwnerId": 13,
      "KeyId": "1011",
      "OperatorAccount": null,
      "TargetAccount": null,
      "Amount": -100,
      "BeforeAmount": 4718685.5181,
      "AfterAmount": 4718585.5181,
      "Remark": "",
      "CreateDate": "2024-12-20 09:56:42",
      "UpdateDate": "2024-12-20 09:56:42"
    }
  ];

  return {
    State: "Success",
    TotalRecord: 22,
    Data
  }
}

export default main;
